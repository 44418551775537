
const page ="/system/authorize/page";
const deleteInfo = "/system/authorize/delete";  
const getClientAuthorize ="/system/authorize/getClientAuthorize";
const setAuthorize ="/system/authorize/setAuthorize";  
const setClientInfo ="/system/authorize/setClientInfo";  

 

export default {
    getClientAuthorize,page,setAuthorize,setClientInfo,deleteInfo
  }