<template>
    <div class="container">
        <el-container>
            <el-header class="main-user-header">


            </el-header>
            <el-main class="main-center">
                <div class="main-table">
                    <el-table :data="tableData" stripe style="width: 100%" size="small">
                        <el-table-column align="center" prop="clientId" label="客户端Id" width="180">
                        </el-table-column>
                        <el-table-column align="center" prop="clientInfo" label="客户端绑定信息">
                            <template slot-scope="scope">
                                <span style="margin-left:10px ;" slot="reference" class="name-wrapper"
                                    v-for="arr in scope.row.clientInfoDisplay" :key="arr">
                                    <el-tag size="medium" type="success" @click="handleOpenClientInfo(scope.row)">{{ arr }}</el-tag>
                                </span>
                                <el-tag :hidden="scope.row.clientInfoDisplay.length>0"   size="medium" @click="handleOpenClientInfo(scope.row)">点击设置</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="count" label="启动次数" width="150">
                        </el-table-column>
                        <el-table-column align="center" prop="statusDisplay" label="状态" width="150">
                            <template slot-scope="scope">

                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium" :type="scope.row.status ? 'success' : 'danger'">{{
                                        scope.row.statusDisplay }}</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="updateDate" label="近期操作" width="150">
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="400">
                            <template slot-scope="props">
                                <el-button round size="mini" icon="el-icon-edit" type="success" @click="opeate(props.row)">授权</el-button>
                                <el-button round size="mini" icon="el-icon-delete" v-if="userId ==1"  type="danger" @click="del(props.row)">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="main-page">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="pageSizeArr" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </el-main>


            <el-dialog title="客户端微服务" :visible.sync="dialogFormVisible">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                    @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                </el-checkbox-group>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="handleSetClientInfo()">确 定</el-button>
                </div>
            </el-dialog>
        </el-container>
    </div>
</template>

<script>
import requestApi from "@/api/system.js";
import authorizeApi from "@/api/authorize.js";

export default {

    data() {
        return {
            tableData: null,
            total: 10,
            pageIndex: 1,
            pageSize: 10,
            pageSizeArr: [10, 20, 50, 100],
            currentPage: 1,
            checkAll: false,
            checkedCities: [],
            cities: [],
            isIndeterminate: true,
            dialogTableVisible: false,
            dialogFormVisible: false,
            recordId: 0,
            userId:0
        };
    },

    //生命周期 - 创建完成（访问当前this实例）
    created() {
        this.userId =localStorage.getItem("userId");
        this.detail();
        this.getPage();

    },
    mounted() { },
    computed: {},
    //生命周期 - 挂载完成（访问DOM元素）
    methods: {
        detail() {
            let params = {};
            this.$get(requestApi.detail, params).then((res) => {
                if (res.success && res.statusCode == 200) {
                   let data = res.data;
                   this.cities=data.mhpClientsDisplay;
                
                }
            });
        },
        getPage() {
            this.page = { pageIndex: this.pageIndex, pageSize: this.pageSize };
            this.$post(authorizeApi.page, this.page).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.tableData = res.data.records;
                    this.total = res.data.total;
                   
                }
            });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getPage();
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getPage();
        },
        opeate(item) {
            let title = item.status ? "是否取消授权?" : "是否授权?";
            this.$confirm(title, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$get(authorizeApi.setAuthorize, { recordId: item.recordId }).then((res) => {
                    if (res.success && res.statusCode == 200) {
                        this.getPage();
                    }
                });
            });
        },
        del(item){
            let title =  "是否删除?";
            this.$confirm(title, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$del(authorizeApi.deleteInfo, { recordId: item.recordId }).then((res) => {
                    if (res.success && res.statusCode == 200) {
                        this.getPage();
                    }
                });
            });
        }
        ,
        handleOpenClientInfo(row) {
        
            this.checkedCities = row.clientInfoDisplay;
            this.dialogFormVisible = true;
            this.recordId = row.recordId;
        },
        handleSetClientInfo() {
            let form = { key: this.recordId, values: this.checkedCities };
            this.$post(authorizeApi.setClientInfo, form).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.getPage();
                    this.dialogFormVisible = false;
                }
            });
        },
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.cities : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        }
    },
};
</script>

<style>
.container{
    height: 100%;
}
.main-center .main-page {
    float: right;
}
</style>